import { colors } from "@apollo/space-kit/colors";
import {
  components,
  createColorPalette,
  createGrayPalette,
} from "@apollo/chakra-helpers";
import { extendTheme } from "@chakra-ui/react";

const { grey, green, red, silver, yellow, indigo, pink, blilet, midnight } =
  colors;

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  semanticTokens: {
    colors: {
      bg: {
        default: "white",
        _dark: "gray.800",
      },
      bgSecondary: {
        default: "gray.50",
        _dark: "gray.700",
      },
      primary: {
        default: "indigo.500",
        _dark: "indigo.200",
      },
      success: {
        default: "green.500",
        _dark: "green.200",
      },
      failure: {
        default: "red.500",
        _dark: "red.200",
      },
      link: {
        default: "indigo.500",
        _dark: "indigo.200",
      },
      inactive: {
        default: "gray.500",
        _dark: "gray.400",
      },
    },
  },
  textStyles: {
    smallCaps: {
      fontFamily: "mono",
      textTransform: "uppercase",
      fontWeight: "semibold",
      lineHeight: "none",
    },
  },
  styles: {
    global: {
      ".grecaptcha-badge": { visibility: "hidden" },
      body: {
        color: blilet.darkest,
        height: "100%",
      },
      html: {
        height: "100%",
      },
      "#___gatsby, #gatsby-focus-wrapper": {
        height: "100%",
      },
      a: {
        transition: "color 0.2s ease-in-out",
      },
    },
  },
  components: {
    ...components,
    Container: {
      baseStyle: {
        px: "24px",
      },
    },
    Heading: {
      baseStyle: {
        color: "blue.900",
      },
      variants: {
        heading1: {
          fontSize: ["56px", "56px", "88px"],
          lineHeight: "1.2em",
          fontWeight: "400",
          mb: "0.1em",
          color: "blue.900",
        },
        heading2: {
          fontSize: "32px",
          lineHeight: "1.2em",
          color: "blue.900",
          fontWeight: "500",
          marginBottom: "24px",
        },
        heading3: {
          fontSize: "24px",
          lineheight: "1.2em",
          color: "blue.900",
          fontWeight: "500",
          marginBottom: "24px",
        },
      },
    },
    Link: {
      baseStyle: {
        fontWeight: 500,
      },
    },
    Text: {
      baseStyle: {
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "1.5em",
        color: "gray.500",
      },
      variants: {
        bodySm: {
          fontSize: "1em",
        },
        bodyMd: {
          fontSize: "1.4em",
        },
        bodyLg: {
          fontSize: "1.7em",
          fontWeight: "500",
        },
      },
    },
    Button: {
      variants: {
        primary: {
          borderRadius: "full",
          backgroundColor: "white",
          fontWeight: "regular",
          alignSelf: "flex-start",
          borderWidth: 1,
          borderColor: "silver.900",
          color: "blue.800",
          _hover: { bg: "purple.500", color: "white", textDecoration: "none" },
          _loading: {
            color: "blue.800",
          },
        },
      },
    },
  },
  fonts: {
    body: "Hanken Grotesk, sans-serif",
    heading: "Hanken Grotesk, sans-serif",
    mono: "IBM Plex Mono, monospace",
  },
  colors: {
    gray: createGrayPalette(silver, grey, midnight),
    indigo: createColorPalette(indigo),
    pink: createColorPalette(pink),
    blue: createColorPalette(blilet),
    green: createColorPalette(green),
    red: createColorPalette(red),
    yellow: createColorPalette(yellow),
    brand: createColorPalette(pink),
  },
});

export default theme;
