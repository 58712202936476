exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-[uuid]-tsx": () => import("./../../../src/pages/ai/[uuid].tsx" /* webpackChunkName: "component---src-pages-ai-[uuid]-tsx" */),
  "component---src-pages-ai-index-tsx": () => import("./../../../src/pages/ai/index.tsx" /* webpackChunkName: "component---src-pages-ai-index-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-01-what-is-graphql-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/01-what-is-graphql.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-01-what-is-graphql-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-02-the-query-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/02-the-query.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-02-the-query-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-03-introducing-types-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/03-introducing-types.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-03-introducing-types-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-04-scalars-objects-lists-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/04-scalars-objects-lists.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-04-scalars-objects-lists-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-05-nullability-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/05-nullability.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-05-nullability-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-06-querying-between-types-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/06-querying-between-types.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-06-querying-between-types-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-07-schema-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/07-schema.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-07-schema-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-08-enums-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/08-enums.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-08-enums-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-09-interfaces-and-unions-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/09-interfaces-and-unions.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-09-interfaces-and-unions-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-10-arguments-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/10-arguments.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-10-arguments-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-11-mutations-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/11-mutations.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-11-mutations-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-16-graphos-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/16-graphos.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-16-graphos-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-17-how-does-graphql-work-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/17-how-does-graphql-work.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-17-how-does-graphql-work-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-18-federated-architecture-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/18-federated-architecture.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-18-federated-architecture-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-19-graphql-for-rest-devs-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/19-graphql-for-rest-devs.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-19-graphql-for-rest-devs-mdx" */),
  "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-20-top-graphql-tools-mdx": () => import("./../../../src/templates/learn.tsx?__contentFilePath=/opt/build/repo/src/content/learn/20-top-graphql-tools.mdx" /* webpackChunkName: "component---src-templates-learn-tsx-content-file-path-opt-build-repo-src-content-learn-20-top-graphql-tools-mdx" */)
}

